.mathquill4quill-mathquill-input {
  border: 1px solid #ccc;
  font-size: 26px;
  min-height: 26px;
  margin: 16px 0;
  width: 100%;
  height: 120px;
}

.mq-root-block {
  padding: 10px !important;
}

.mathquill4quill-operator-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-height: 50px;
  padding: 12px;
  margin: 5px;
  border: 1px solid #E3E4E6;
  border-radius: 7px;
  background-color: #fff;
}

.mathquill4quill-operator-button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.mathquill4quill-operator-button-symbol {
  border: none;
  background-color: #fff;
}
.mathquill4quill-operator-button-description {
  background: #F2F2F2;
  padding: 0px 8px;
  border-radius: 4px;
  margin-left: 5px;
}

.mathquill4quill-operator-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 12px;
}
.mathquill4quill-footer {
  height: 30px;
}

.mathquill4quill-history-button {
  overflow: hidden;
  margin: 5px;
  width: 270px;
  height: 65px;
  min-height: 60px;
  background-color: #fff;
  border-color: #000;
  border-radius: 7px;
  border-width: 2px;
  cursor: pointer;
  transition: background-color 0.3s linear;
  opacity: 1;
}

.mathquill4quill-history-button:hover {
  background-color: rgb(239, 240, 241);
  opacity: 0.7;
}

.mathquill4quill-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 150px;
  overflow: auto;
}

.mathquill4quill-latex-input {
  visibility: hidden !important;
  padding: 0 !important;
  border: 0 !important;
  width: 0 !important;
}

/* Style the tabs */
.tabs {
  overflow: hidden;
  border-bottom: 1px solid #DEDEDE;
  background-color: #fff;
}
.tab {
  background-color: inherit;
  border: none;
  padding-bottom: 15px;
}
.tab.active {
  font-weight: 600;
  color: #1A5AFF;
  background-color: inherit;
  border: none;
  border-bottom: 2px solid #1A5AFF;
  padding-bottom: 15px;
}

/* sider */
.tab-character__sider, .tab-structure__sider {
  float: left;
  height: inherit;
  width: 30%;
  padding: 11px;
  border-right: 1px solid #DEDEDE;
}
.tab-character__sider.active, .tab-structure__sider.active {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.tab-character__sider.inactive, .tab-structure__sider.inactive {
  display: none;
}
.tab-character__sider-item, .tab-structure__sider-item {
  padding: 8px;
  border: none;
  background-color: #fff;
  text-align: start;
  cursor: pointer;
}
.tab-character__sider-item.active, .tab-structure__sider-item.active {
  background: #E6F2FF;
  border-radius: 2px;
}

/* tab content */
.tab-content-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.tab-content-wrapper.active {
  height: 500px;
}
.tab-content-wrapper.inactive {
  display: none;
}
.tab-content-detail-wrapper {
  float: right;
  width: 70%;
  height: inherit;
  overflow-y: auto;
} 
.tab-content-detail-wrapper::-webkit-scrollbar {
  width: 8px;
}
.tab-content-detail-wrapper::-webkit-scrollbar-thumb {
  background-color: #E3E4E5;
  border-radius: 4px;
}
.tab-content-detail-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #d6d6d6;
}
.tab-content-detail-wrapper::-webkit-scrollbar-track {
  background-color: inherit;
}

/* tab detail */
.tab-character__detail, .tab-structure__detail {
  padding: 11px;
  color: #000;
}
.tab-character__detail:last-child, .tab-structure__detail:last-child {
  margin-bottom: 70%;
}
.tab-character__detail-item, .tab-structure__detail-item {
  display: flex;
  flex-wrap: wrap;
}
.tab-character__detail-title, .tab-structure__detail-title {
  font-weight: 600;
  font-size: 16px;
}

.math4quill-ql-action {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  background: #1616b9;
}

.ql-action::after {
  content: "Thêm công thức" !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  color: white;
}
.ql-formula {
  font-weight: 600;
}
